@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  /* overflow: hidden; */
}

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  color: #a5b1c0;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row-justify-between {
  display: flex;
  justify-content: space-between;
}
